<template>
  <div>
    <el-dialog
      title="取消订单"
      :visible.sync="cancelDialogVisible"
      :close-on-click-modal="false"
      width="22%"
      center
      :before-close="cancelCallbackFun"
    >
      <!-- 顶部分割线 -->
      <el-divider></el-divider>
      <!-- 中间内容 -->
      <div class="pop_content">
        <p>是否取消当前订单？</p>
      </div>
      <!-- 底部分割线 -->
      <div class="fgx">
        <el-divider></el-divider>
      </div>
      <!-- 确认 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmFun"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { cancelOreder } from "@/api/order.js";
export default {
  data() {
    return {
    };
  },
  props:{
    cancelDialogVisible:{
      type:Boolean,
      default:false,
    },
    orderId:{
      type:Number,
      default:0
    }
  },
  methods: {
    cancelCallbackFun(){
      // 取消
      this.$emit('cancelCallbackFun',false)
    },
    confirmFun(){
      // 确认按钮
      console.log('id',this.orderId);
      cancelOreder({
        'order_id':this.orderId,
      }).then(res =>{
          // 匹配成功
          this.$message({
            showClose: true,
            message: '取消订单成功',
            type: 'success'
          });
          this.cancelCallbackFun()
      }).catch(err => {
        this.cancelCallbackFun()
      })

    }
  },
};
</script>
<style lang="scss" scoped>
// 间距
/deep/.el-dialog--center .el-dialog__body {
  padding: 0;
  .el-divider--horizontal {
    margin: 0;
  }
}
.pop_content {
  margin: 60px 73px;
  font-size: 20px;
  font-weight: 400;
  color: #666666;
  letter-spacing: 1px;
  text-align: center;
}

// 确认
/deep/.el-button--primary {
  color: #935d14;
  border: none;
  width: 350px;
  height: 50px;
  background: linear-gradient(270deg, #f3bc70 0%, #f8e2c4 100%);
  border-radius: 4px;
  font-size: 16px;
  margin-top: 20px;
}
.fgx {
  margin-top: 40px;
}
</style>
