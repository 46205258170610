<template>
  <div>
    <el-dialog
      title="是否匹配成功"
      :visible.sync="matchDialogVisible"
      :close-on-click-modal="false"
      width="22%"
      center
      :before-close="matchCallbackFun"
    >
      <!-- 顶部分割线 -->
      <el-divider></el-divider>
      <!-- 中间内容 -->
      <div class="pop_content">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="已匹配" name="first">
            <div class="service_content">
              <el-input v-model="serve" placeholder="请输入服务内容"></el-input>
            </div>
            <div class="service_content">
              <el-select v-model="bz" placeholder="请选择币种">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="service_content">
              <el-input v-model="money" placeholder="请输入课程金额"></el-input>
            </div>
          </el-tab-pane>
          <el-tab-pane label="未匹配" name="second">
            <div class="service_course">
              很遗憾，当前海师未能匹配成功 请选择另一位海师。
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <!-- 底部分割线 -->
      <div class="fgx">
        <el-divider></el-divider>
      </div>
      <!-- 确认 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="matchCallbackFun">取 消</el-button>
        <el-button @click="handleaffirm" type="primary">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { order } from '@amcharts/amcharts4/.internal/core/utils/Number';
import { number } from '@amcharts/amcharts4/core';
import { updataOreder } from "@/api/order.js";
export default {
  data() {
    return {
      activeName: "first",
      money:"",
      serve:"",
      tabIndex:0,
       options: [{
          value: '1',
          label: '￥人民币'
        }, {
          value: '2',
          label: '$ 美元'
        }],
        bz: ''//币种
    };
  },
  props:{
    matchDialogVisible:{
      type:Boolean,
      default:false
    },
    orderId:{
      type:Number,
      default:0
    }
  },
  methods: {
    handleClick(tab) {
      this.tabIndex = tab.index;
      console.log('handleClick',tab.index);
    },
    matchCallbackFun(){
      console.log(222);
      this.$emit('matchCallbackFun', false)
    },
    handleaffirm(){
      let type = this.tabIndex * 1 + 1;
      console.log('affirm',type)
      updataOreder({
        'type':type,
        'service_content':this.serve,
        'service_amount':this.money,
        'order_id':this.orderId,
        'currency':this.bz,
      }).then(res =>{
        console.log(111,res);
        // 成功
        if(type == 1){ //0/1=匹配/确认
          // 匹配成功
          this.$message({
            showClose: true,
            message: '匹配成功',
            type: 'success'
          });
          this.matchCallbackFun();
        }else{
          // 取消成功
          this.$message({
            showClose: true,
            message: '取消匹配成功',
            type: 'success'
          });
          this.matchCallbackFun();
        }
      }).catch(err => {
        this.matchCallbackFun()
      })
    },
  }
};
</script>
<style lang="scss" scoped>
// 间距
/deep/.el-dialog--center .el-dialog__body {
  padding: 0;
  .el-divider--horizontal {
    margin: 0;
  }
}

// 匹配标题
.pop_content {
  margin: 30px 0 0 0px;
  font-size: 16px;
  font-weight: bold;
  color: #d99d66;
  /deep/.el-tabs__nav-wrap::after {
    background-color: transparent !important;
  }
  /deep/.el-tabs__header {
    margin-left: 115px;
  }
  /deep/.el-tabs__item {
    font-size: 16px;
    font-weight: bold;
    color: #d99d66;
    padding: 0 50px;
  }
  /deep/ .el-tabs__active-bar {
    width: 45px;
    height: 4px;
    background: #d99d66;
    border-radius: 2px;
  }
  .service_content {
    margin-top: 25px;
    text-align: center;
  }
  /deep/.el-input__inner {
    width: 350px;
    height: 44px;
    background: #fafafa;
    border-radius: 4px;
    border: 1px solid #ededed;
  }
  /deep/.el-button + .el-button {
    margin-left: 40px;
  }
  /deep/ .el-tabs__nav {
    margin-left: 100px;
  }
}

// 未匹配
.service_course {
  width: 303px;
  font-size: 20px;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
  letter-spacing: 1px;
  text-align: center;
  margin: 40px 73px 50px 73px;
}

// 取消
/deep/.el-button {
  width: 150px;
  height: 50px;
  border-radius: 4px;
  border: 1px solid #ededed;
  font-size: 16px;
  font-weight: 400;
  color: #999999;
}
// 确认
/deep/.el-button--primary {
  color: #935d14;
  border: none;
  width: 150px;
  height: 50px;
  background: linear-gradient(270deg, #f3bc70 0%, #f8e2c4 100%);
  border-radius: 4px;
  font-size: 16px;
  margin-top: 20px;
}
.fgx {
  margin-top: 40px;
}
</style>
